.tab {
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    span:not(.dot) {
        color: #fff;
    }
    .row {
        &.active {
            &::after {
                @media screen and (min-width: 980px) {
                    bottom: -22px;
                }
            }
        }
        span:not(.dot) {
            color: #fff;
        }
    }
    .column {
        padding-bottom: 20px;
        span:not(.dot) {
            position: relative;
            padding: 20px 10px;
            display: block;
            @media screen and (min-width: 425px) {
                padding: 30px 10px;
            }
            @media screen and (min-width: 980px) {
                padding: 0 15px 5px;
                text-align: center;
            }
            &::after {
                transition: background-color 0.3s ease-in-out;
                background-color: transparent;
                height: 3px;
                width: 100%;
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
        svg {
            color: var(--primary-link-color);
            width: 100%;
            height: 100%;
            @media screen and (min-width: 980px) {
                margin-bottom: 5px;
                width: initial;
                height: initial;
            }
        }
        &.active {
            @media screen and (min-width: 980px) {
                &::after {
                    height: 5px;
                    width: 100%;
                    border-radius: 20px;
                    content: '';
                    position: absolute;
                    top: 112.5px;
                    left: 0;
                    background-color: var(--secondary-max-brand-color, #00a2ff);
                }
            }
            span {
                color: var(--secondary-max-brand-color, #00a2ff);
            }
            .dot {
                transform: scale(1.5);
                background-color: var(--primary-link-color);
            }
        }
        &:hover {
            @media screen and (min-width: 980px) {
                &::after {
                    transition: background-color 0.3s ease-in-out;
                    height: 5px;
                    width: 100%;
                    border-radius: 20px;
                    content: '';
                    position: absolute;
                    top: 112.5px;
                    left: 0;
                    background-color: var(--secondary-max-brand-color, #00a2ff);
                }
                span {
                    color: var(--secondary-max-brand-color, #00a2ff);
                }
            }
        }
    }
    /* stylelint-disable */
    .row {
        align-items: center;
        gap: 10px;
        position: relative;
        padding-bottom: 20px;
        height: 100%;
        > div {
            display: flex;
        }
        span:not(.dot) {
            font-family: var(--rubik);
            font-size: 16px;
            font-weight: 700;
            color: #fff;
            text-align: left;
        }
        &.active {
            @media screen and (min-width: 980px) {
                &::after {
                    transition: background-color 0.3s ease-in-out;
                    height: 6px;
                    width: 100%;
                    border-radius: 20px;
                    content: '';
                    position: absolute;
                    bottom: -3.9px;
                    left: 0;
                    background-color: var(--secondary-max-brand-color, #00a2ff);
                }
            }
            span {
                color: var(--secondary-max-brand-color, #00a2ff);
            }
            .dot {
                transform: scale(1.5);
                background-color: var(--primary-link-color);
            }
        }
        &:hover {
            @media screen and (min-width: 980px) {
                &::after {
                    transition: background-color 0.3s ease-in-out;
                    height: 6px;
                    width: 100%;
                    border-radius: 20px;
                    content: '';
                    position: absolute;
                    bottom: -3.9px;
                    left: 0;
                    background-color: var(--secondary-max-brand-color, #00a2ff);
                }
                span {
                    color: var(--secondary-max-brand-color, #00a2ff);
                }
            }
        }
    }
}

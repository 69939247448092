.tab {
    cursor: pointer;
    font-family: var(--source);
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    color: #202730;
    display: flex;
    align-items: center;
    padding: 0 60px 0 10px;
    @media screen and (min-width: 980px) {
        transition: color 0.3s ease-in-out;
        flex-direction: column;
        padding: 0;
    }
    @media screen and (min-width: 1023px) {
        font-size: 22px;
    }
    span:not(.dot) {
        font-size: 16px;
        text-align: center;
    }
    .column {
        text-align: center;
        position: relative;
        &.active {
            @media screen and (min-width: 980px) {
                &::after {
                    transition: background-color 0.3s ease-in-out;
                }
            }
        }
    }
    .row {
        padding-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        &.active {
            @media screen and (min-width: 980px) {
                &::after {
                    transition: background-color 0.3s ease-in-out;
                }
            }
        }
    }
}

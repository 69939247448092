.tab {
    cursor: pointer;
    font-family: var(--source);
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    color: #202730;
    display: flex;
    align-items: center;
    padding: 0 60px 0 10px;
    @media screen and (min-width: 980px) {
        transition: color 0.3s ease-in-out;
        flex-direction: column;
        padding: 0;
    }
    @media screen and (min-width: 1023px) {
        font-size: 22px;
    }
    span:not(.dot) {
        position: relative;
        padding: 20px 10px;
        display: block;
        @media screen and (min-width: 425px) {
            padding: 30px 10px;
        }
        @media screen and (min-width: 980px) {
            padding: 0 15px 5px;
            text-align: center;
        }
        &::after {
            transition: background-color 0.3s ease-in-out;
            background-color: transparent;
            height: 3px;
            width: 100%;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    svg {
        color: var(--primary-link-color);
        width: 100%;
        height: 100%;
        @media screen and (min-width: 980px) {
            margin-bottom: 5px;
            width: initial;
            height: initial;
        }
    }
    &.active {
        @media screen and (min-width: 980px) {
            color: var(--primary-link-color);
            span::after {
                background-color: var(--primary-link-color);
            }
            svg {
                color: #7990a1;
            }
        }
        .dot {
            transform: scale(1.5);
            background-color: var(--primary-link-color);
        }
    }
    &:hover {
        @media screen and (min-width: 980px) {
            color: var(--primary-link-color);
            svg {
                color: #7990a1;
            }
            span::after {
                background-color: var(--primary-link-color);
            }
        }
    }
}
